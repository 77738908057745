
class Tabs {
  constructor() {
    if ($('.tabs-component').length > 0) {
      this.onSwitchTabs();
    }
  }
  onSwitchTabs() {
    $('a[data-toggle="tab"]').on('shown.bs.tab', (e) => {
      e.preventDefault();
      let nowActiveTab = e.target,
        previousActiveTab = e.relatedTarget;

      $(nowActiveTab).attr({
        'aria-selected': 'true'
      });
      $(previousActiveTab).attr({
        'aria-selected': 'false'
      });
      // Extract the hash value (e.g., #week-2) and update carousel
      const activeTabHash = nowActiveTab.hash;
      this.updateCarouselPosition(activeTabHash);
    });
  }

  updateCarouselPosition(activeTabHash) {
    if (activeTabHash) {
      const carouselSelector = `${activeTabHash} .mpc-slick-carousel`;
      // Check if the carousel element exists before calling setPosition
      if ($(carouselSelector).length > 0) {
        $(carouselSelector)
          .slick('setPosition');
      }
    }
  }
}

export default new Tabs();
