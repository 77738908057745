import Form from '../base/form.js';
import Spinner from './spinner.js';
import UserHandler from '../base/user.js';
import { CID_COOKIE, _LOCALE_, requestUrls } from '../../base/vars.js';
import { isiOS, EventHandler, getJsonObject, getServiceUrl, CookieHandler, callService } from '../../base/utils.js';
import Analytics from '../../base/wyn-analytics-module.js';
import ConfigsUtils from '../../base/aem-configs/config-utils.js';
import { getSessionStorage } from '../../base/session-handler.js';

class PreferencesForm {
  constructor() {
    this.formSelector = '#preferencesUpdate';
    this.$form = $(this.formSelector);
    this.brandsAndAmenities = {
      brands: [],
      amenities: [],
      brandsNames: [],
      amenitiesNames: [],
      brandsInput: null,
      amenitiesInput: null,
    };
    this.multiselects = {};
    this.savedPreferences = {};
    this.stateUpdated = {
      amenities: false,
      brands: false,
      sortBy: false,
    };

    // Analytics variables
    this.analyticsVars = {
      hotelBrandSelection: '',
      earningSelection: '',
      travelPartner: '',
      subscribeEstatements: '',
      wyndhamRewardsPartnerOptIn: '',
      sort: 'Sort preference',
      brands: 'Brand preference',
      amenities: 'Amenities preference',
      sortByMap: {
        none: 'none',
        DIS: 'Distance',
        PLH: 'Price - low to high',
        PHL: 'Price - high to low',
      },
    };

    if (this.$form.length > 0) {
      this.$form.show();
      const self = this;
      ['brands', 'amenities'].forEach((elId) => {
        const selectElement = $(`#${elId}`);
        const selectElementCopy = this.clearSelectOptions(elId);
        selectElement.css('display', 'none');
        this.$form.find(`select#${elId}`).each((index, el) => {
          el.setAttribute('multiple', 'multiple');
          this.multiselects[elId] = $(el).multiselect({
            buttonText: function() {
              return '';
            },
            onChange: function() {
              self.stateUpdated[elId] = true;
              const selectedOptions = $(el).find('option:selected');
              const selectedItems = [];
              const selectedItemsNames = [];
              $(selectedOptions).each(function() {
                selectedItems.push($(this).val());
                selectedItemsNames.push($(this).text());
              });
              self.brandsAndAmenities[elId] = selectedItems;
              self.brandsAndAmenities[`${elId}Names`] = selectedItemsNames;
              const submitBtn = self.$form.find('.submit');
              if (submitBtn && submitBtn.is(':disabled')) {
                Form.enableFormField(submitBtn);
                submitBtn.text(submitBtn.data('save-label'));
              }
              Array.from(selectElementCopy.options).forEach(function(option) {
                option.selected = selectedItems.includes(option.value);
              });
            },
          });
        });
      });
      this.setSavedPreferences();
      this.partnersInit();
      const el = document.querySelector('#airfare .flex-form-row.hidden');
      if (el) {
        const input = el.querySelector('#firstName');
        if (input && !input.value) {
          el.remove();
        }
      }
      const corpCodeElem = document.querySelector('#corpCode');
      if (corpCodeElem) {
        corpCodeElem.addEventListener('keypress', (event) => this.numOnly(event));
      }
      $('[name="sort-preference"]').on('click', () => {
        this.stateUpdated.sortBy = true;
      });
      const submitBtn = document.querySelector('#preferencesUpdate button.submit');
      if(submitBtn) {
        submitBtn.addEventListener('click', event => {
          const preferencesData = this.createPreferenceData();
          const { type, value } = this.getAnalyticsPayload(preferencesData.data);
          if (type && value) {
            console.log('About to call Analytics method :: ', {type, value});
            Analytics.updateBookingPreferenceAnalytics(type, value);
          }
          Object.keys(this.stateUpdated).forEach((key) => {
            this.stateUpdated[key] = false;
          });
        });
      }
      this.formInit();
    }
    var flyerLabel = $('label[for=flyerNum]');
    flyerLabel.css('color', '#666666');
    $('#flyerNum').on('keyup', function() {
      $('#pref-error-message').css('display', 'none');
      flyerLabel.css('color', '#666666');
    });
  }

  clearSelectOptions(elId) {
    const select = document.querySelector(`#${elId}`);
    if (select) {
      select.value = '';
      Array.from(select.options).forEach((option) => {
        option.selected = false;
      });
    }
    return select;
  }

  clearMultiselectOptions() {
    ['brands', 'amenities'].forEach((elId) => {
      this.multiselects[elId].multiselect('deselectAll', false);
    });
  }

  formInit() {
    this.$form
      .on('init.form.fv', (e, data) => {
        Form.removeFormError($(e.target));
        this.checkSMSEligibility();
      })
      .formValidation({
        excluded: ':hidden',
        button: {
          selector: '.submit',
          disabled: '',
        },
        live: 'enabled',
        fields: {
          preferredLanguage: {
            validators: {
              notEmpty: {
                enabled: true,
              },
            },
          },
          optIn: {},
          preferredSMSMarketing: {},
          preferred: {},
          preferredThirdParty: {},
          brands: {},
          amenities: {},
          smokingRoom: {},
          preferredRoom: {},
        },
      })
      .on('success.form.fv', (e) => {
        Analytics.satelliteTracker('smsOptIn');
        e.preventDefault();
        let data = this.createFormData();
        this.postForm(data);
      })
      .on('err.field.fv', Form.handleMultipleErrorLabels);
  }

  someExists(array1, array2) {
    return array1.some((a1) => !array2.includes(a1));
  }

  areArraysDifferent(array1, array2) {
    if (!array1 || !array2) {
      return false;
    }

    if (!Array.isArray(array1) || !Array.isArray(array2)) {
      return false;
    }

    return this.someExists(array1, array2) || this.someExists(array2, array1);
  }

  getAnalyticsPayload(data) {
    const payload = {
      type: [],
      value: [],
    };
    if (data.sortBy && this.stateUpdated.sortBy) {
      payload.type.push(this.analyticsVars.sort);
      payload.value.push(this.analyticsVars.sortByMap[data.sortBy]);
    }
    if (this.stateUpdated.amenities) {
      payload.type.push(this.analyticsVars.amenities);
      payload.value.push(data.amenitiesNames.join(','));
    }
    if (this.stateUpdated.brands) {
      payload.type.push(this.analyticsVars.brands);
      payload.value.push(data.brandsNames.join(','));
    }
    const result = {
      type: payload.type.join('|'),
      value: payload.value.join('|'),
    };
    return result;
  }

  loadPartners() {
    const partners = ConfigsUtils.getAirlineMilesPartners();
    const europeanPartnerList = partners && partners.europeanPartnerList ? partners.europeanPartnerList : [];
    const defaultPartnerList = partners && partners.defaultPartnerList ? partners.defaultPartnerList : [];
    const europeanPartnerLocales = ['de-de', 'en-gb'];
    const travelPartnerSelect = $('#travelPartner');

    if (europeanPartnerList && europeanPartnerLocales.includes(window._LOCALE_)) {
      $(europeanPartnerList).each((i, partner) => {
        travelPartnerSelect.append(new Option(partner.description, partner.partnerProgramCode));
      });
    } else if (defaultPartnerList) {
      $(defaultPartnerList).each((i, partner) => {
        travelPartnerSelect.append(new Option(partner.description, partner.partnerProgramCode));
      });
    }
    if (isiOS()) {
      travelPartnerSelect.append('<optgroup label="" class="ios-wa"></optgroup>');
    }
  }

  partnersInit() {
    this.loadPartners();
  }

  numOnly(event) {
    const { which, charCode } = event;
    const input = event.target;
    const regex = new RegExp('^[0-9]{0,10}$');
    const value = String.fromCharCode(!charCode ? which : charCode);
    const newValue = input.value + value;
    if (regex.test(newValue)) {
      return true;
    }
    event.preventDefault();
    return false;
  }

  checkSMSEligibility() {
    const smsMarketing = $('.sms-marketing'),
      smsPersonalizationConfigs = ConfigsUtils.getSMSPersonalizationDisabled();
    let country = $('[data-binding="addresses.0.countryCode"]'),
      eligibleCountries = [];
    if (getSessionStorage(CID_COOKIE)) {
      const user = getSessionStorage(CID_COOKIE);
      country = user.addresses[0].countryCode;
    }
    if (!smsPersonalizationConfigs || !country) {
      return;
    }

    eligibleCountries = smsPersonalizationConfigs.attributes['sms-personalization-eligible-countries'];
    if (eligibleCountries.includes(_.isString(country) ? country : country.val()) && _LOCALE_ == 'en-us') {
      smsMarketing.removeClass('hidden');
    } else {
      smsMarketing.addClass('hidden');
    }
  }

  createFormData() {
    const checkboxInputs = this.$form.find(':checkbox'),
      selectInputs = this.$form.find('input, select'),
      // EARN_POINTS = 'earnPoints',
      EARN_AIRFARE = 'earnAirfare',
      travelPartner = $('#travelPartner').val(),
      flyerNumber = $('#flyerNum').val();
    let data = window.User;

    data = {
      ...data,
      updatePreferences: true,
      updateCustomer: false,
      updateAddress: false,
      updateAlias: true,
      updateEmail: false,
      memberIdentifier: data.accountNumber,
    };

    const partnerIndex = _.findIndex(data.aliases, (a) => a.typeCode === 'PartnerPreferred');
    const hasPartner = partnerIndex != -1;
    const pointsMethod = document.querySelector('[type=radio][name=preferredrewards]:checked').id;

    if (pointsMethod == EARN_AIRFARE) {
      // update if alias exists
      let currentAlias = data.aliases[partnerIndex];
      if (hasPartner) {
        currentAlias.method = 'update';
        currentAlias.partnerProgramCode = travelPartner;
        currentAlias.alias = flyerNumber;
      } else {
        //add if no alias exists
        const aliasData = {
          method: 'add',
          typeCode: 'PartnerPreferred',
          partnerProgramCode: travelPartner,
          alias: flyerNumber,
        };
        data.aliases = [...data.aliases, aliasData];
      }
    } else {
      // if points are selected invoke delete method for miles
      let currentAlias = data.aliases[partnerIndex];
      if (hasPartner) {
        currentAlias.method = 'delete';
      }
    }
    localStorage.setItem('PREF_POINTS_MILES', pointsMethod);

    // add checkbox inputs to data
    $.each(
      checkboxInputs,
      (i, e) => {
        const preferenceIds = ['preferred', 'preferredThirdParty', 'preferredSMSMarketing'];
        if (preferenceIds.includes($(e).attr('id'))) {
          const ruleDetailCode = $(e).data('binding').split('.')[1];
          let index = _.findIndex(data.preferences, (p) => p.preferenceRuleDetailCode === ruleDetailCode);
          if (index != -1) {
            data.preferences[index].value = $(e).prop('checked').toString();
          }
        }
      },
      data
    );

    // add select inputs to data
    $.each(
      selectInputs,
      (i, e) => {
        if ($(e).attr('id') === 'preferredLanguage') {
          const ruleDetailCode = $(e).data('binding');
          let index = _.findIndex(data.preferences, (p) => {
            return p.preferenceRuleDetailCode === ruleDetailCode.split('.')[1];
          });
          if (index != -1) {
            data.preferences[index].value = e.value;
          } else {
            // see if previous lang exists
            const langPreferenceIndex = _.findIndex(data.preferences, (p) => p.preferenceRuleCode === 'CP_LANG_PREF');
            const hasLangPreference = langPreferenceIndex != -1;

            if (hasLangPreference) {
              data.preferences[langPreferenceIndex].value = e.value;
            } else {
              const langPref = {
                preferenceRuleCode: 'CP',
                preferenceRuleDetailCode: 'CP_LANG_PREF',
                value: e.value,
              };
              data.preferences = [...data.preferences, langPref];
            }
          }
        }
      },
      data
    );
    return data;
  }

  createPreferenceData() {
    const data = {};
    const preferences = {};
    const preferredRoom = document.querySelector('#preferredRoom');
    const sortByPreference = document.querySelector('[name=sort-preference]:checked');
    const earningPreference = document.querySelector('[name=preferredrewards]:checked');
    preferences.action = 'ADD';
    preferences.type = 'FilterCriteria';
    preferences.uniqueId = window.User.accountNumber;
    data.BedQuantity = preferredRoom ? preferredRoom.value : '';
    data.sortBy = sortByPreference ? sortByPreference.value : '';
    data.brands = this.brandsAndAmenities.brands.filter((b) => b);
    data.Smoking = document.querySelector('#smokingRoom').checked;
    data.corporateCode = document.querySelector('#corpCode').value;
    data.amenities = this.brandsAndAmenities.amenities.filter((a) => a);
    data.brandsNames = this.brandsAndAmenities.brandsNames.filter((b) => b);
    data.earningPreference = earningPreference ? earningPreference.value : '';
    data.amenitiesNames = this.brandsAndAmenities.amenitiesNames.filter((a) => a);
    preferences.data = data;
    return preferences;
  }

  setSavedPreferences() {
    document.addEventListener('srp.user.preferences.filterCriteria', (event) => {
      this.savedPreferences = event.detail;
      this.applySavedPreferences();
    });
  }

  applySavedPreferences() {
    if (this.savedPreferences.amenities) {
      this.brandsAndAmenities.amenities = [...this.savedPreferences.amenities];
      if (this.multiselects.amenities) {
        this.brandsAndAmenities.amenities.forEach((a) => {
          this.multiselects.amenities.multiselect('select', a);
        });
        this.multiselects.amenities.multiselect('rebuild');
      }
    }
    if (this.savedPreferences.brands) {
      this.brandsAndAmenities.brands = [...this.savedPreferences.brands];
      if (this.multiselects.brands) {
        this.brandsAndAmenities.brands.forEach((b) => {
          this.multiselects.brands.multiselect('select', b);
        });
        this.multiselects.brands.multiselect('rebuild');
      }
    }

    if (this.savedPreferences.amenitiesNames) {
      this.brandsAndAmenities.amenitiesNames =  [ ...this.savedPreferences.amenitiesNames ];
    }

    if (this.savedPreferences.brandsNames) {
      this.brandsAndAmenities.brandsNames = [ ...this.savedPreferences.brandsNames ];
    }

    if (this.savedPreferences.sortBy) {
      const sortByElement = document.querySelector(`[name="sort-preference"][value="${this.savedPreferences.sortBy}"]`);
      if (sortByElement) {
        sortByElement.checked = true;
      }
    }

    if (this.savedPreferences.corporateCode) {
      const corpCodeElem = document.querySelector('#corpCode');
      if (corpCodeElem) {
        corpCodeElem.value = this.savedPreferences.corporateCode;
        corpCodeElem.dispatchEvent(new CustomEvent('change', { bubbles: true }));
      }
    }

    if (this.savedPreferences.BedQuantity) {
      const roomPrefElement = document.querySelector('#preferredRoom');
      if (roomPrefElement) {
        switch (this.savedPreferences.BedQuantity) {
          case 'no-preferences':
            this.savedPreferences.BedQuantity = '0';
            break;
          case '1-bed':
            this.savedPreferences.BedQuantity = '1';
            break;
          case '2+-beds':
            this.savedPreferences.BedQuantity = '2';
            break;
        }
        roomPrefElement.value = this.savedPreferences.BedQuantity;
      }
    }

    const smokeRoom = document.querySelector('#smokingRoom');
    if (smokeRoom) {
      smokeRoom.checked = this.savedPreferences.Smoking;
    }
    $('.multiselect-container.dropdown-menu').off('touchstart');
  }

  getSavedPreferences() {
    const refreshToken = CookieHandler.readCookie('refresh_token');
    const accessToken = CookieHandler.readCookie('access_token');
    const loglocale = CookieHandler.readCookie('loglocale');
    const user = JSON.parse(localStorage.getItem('OT_WHG_SESSION'));
    const Cookie = `refresh_token=${refreshToken};access_token=${accessToken};loglocale=${loglocale}`;
    const headers = {
      Cookie,
      'Content-Type': 'application/json',
    };
    const payload = {
      uniqueId: user.membershipId,
      type: 'FilterCriteria',
      action: 'GET',
    };
    callService('myFavourite', JSON.stringify(payload), 'POST', headers).then((data) => {
      const filterCriteria = data.FilterCriteria && data.FilterCriteria.length ? data.FilterCriteria[0] : null;
      if (filterCriteria) {
        const customEvent = new CustomEvent('srp.user.preferences.filterCriteria', {
          detail: {
            ...filterCriteria,
          },
        });
        document.dispatchEvent(customEvent);
      }
    });
  }

  submitFormData(url, data, type) {
    Spinner.appendTo(this.$form);
    Form.disableFormField(this.$form.find('.submit'));
    Form.disableFormField(this.$form.find('.cancel'));

    $.ajax({
      url,
      type: 'POST',
      data: JSON.stringify(data),
      contentType: 'application/json',
      context: {
        onFail: {
          scrollToElement: this.$form,
          formElement: this.$form,
          errorElement: $('.preferred-rewards-selection'),
        },
      },
      success: () => {
        if (type === 'existing') {
          EventHandler.triggerEvent('preferences-complete', {
            pageName: 'preferences',
            subscribeEstatements: Analytics.processSubscribeEStatements(this.analyticsVars.subscribeEstatements),
            wyndhamRewardsPartnerOptIn: Analytics.processSubscribeEStatements(
              this.analyticsVars.wyndhamRewardsPartnerOptIn
            ),
            earningSelection: this.analyticsVars.earningSelection,
            hotelBrandSelection: this.analyticsVars.hotelBrandSelection,
            travelPartner: this.analyticsVars.travelPartner,
          });
        }
        if (type === 'new') {
          Spinner.remove(this.$form);
          $('#pref-error-message').css('display', 'none');
          const label = $('label[for=flyerNum]');
          if (label) {
            label.css('color', '#666666');
          }
          Form.showSuccessMessage(this.$form);
          UserHandler.getProfile(() => Form.disableFormField(this.$form.find('.submit')));
        }
      },
      error: (errResponse) => {
        const err = getJsonObject(errResponse);
        if (err && err.responseJSON && err.responseJSON.ErrorMessage) {
          var label = $('label[for=flyerNum]');
          label.css('color', '#e00000');
          $('#pref-error-message').css('display', 'block');
          $('#pref-error-message').html(DOMPurify.sanitize(err.responseJSON.ErrorMessage));
          $('.notification-banner').css('display', 'none');
          $('.notification-banner').remove();
        }
      },
      complete: () => {
        if (type === 'existing') {
          const preferences = this.createPreferenceData();
          const preferenceUrl = getServiceUrl('myFavourite');
          this.submitFormData(preferenceUrl, preferences, 'new');
        } else {
          Form.enableFormField(this.$form.find('.cancel'));
          if (type === 'new') {
            this.getSavedPreferences();
          }
        }
      },
    });
  }

  postForm(data) {
    Spinner.appendTo(this.$form);
    const url = requestUrls['update'];
    Form.disableFormField(this.$form.find('.submit'));
    Form.disableFormField(this.$form.find('.cancel'));

    this.submitFormData(url, data, 'existing');
  }
}

export default new PreferencesForm();
